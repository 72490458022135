<template>
    <page
        id="dashboard"
        :title="$root.translate('Dashboard')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div class="mb-8">
            <ul class="toc">
                <li class="toc-item">
                    <base-link
                        href="#computers"
                    >
                        <open-icon glyph="laptop" /> {{ $root.translate("Computers") }}
                    </base-link>
                </li>
                <li class="toc-item">
                    <base-link
                        href="#emails"
                    >
                        <open-icon glyph="envelope" /> {{ $root.translate("Emails") }}
                    </base-link>
                </li>
                <li class="toc-item">
                    <base-link
                        href="#threats"
                    >
                        <open-icon glyph="shield-alt" /> {{ $root.translate("Threats") }}
                    </base-link>
                </li>
                <li class="toc-item">
                    <base-link
                        href="#network"
                    >
                        <open-icon glyph="wifi" /> {{ $root.translate("Internet Connection") }}
                    </base-link>
                </li>
            </ul>
        </div>

        <!--
        <billables
            id="billables"
            filter-on="unpaid-billables"
            class="nibnut-scroll-target"
        />
        //-->

        <div v-if="!!report && !!report.id">
            <div v-if="report.content.bitdefender" id="computers" class="card nibnut-scroll-target mt-8">
                <div class="card-header">
                    <span class="h4"><open-icon glyph="laptop" /> {{ $root.translate("Computers") }}</span>
                </div>
                <div class="card-body">
                    <div class="columns mb-4">
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.bitdefender.stats.computers"
                                />
                                <div>{{ $root.translate("Managed Computers") }}</div>
                            </div>
                        </div>
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.bitdefender.stats.threats"
                                />
                                <div><span class="mx-1 dot" :style="{ 'background-color': color_code_by_index(0) }"></span>{{ $root.translate("Infected Files") }}</div>
                            </div>
                        </div>
                    </div>
                    <apexchart
                        type="bar"
                        :height="160"
                        :options="chart_options('bitdefender')"
                        :series="chart_data('bitdefender')"
                    />
                </div>
            </div>

            <div v-if="report.content.zerospam" id="emails" class="card nibnut-scroll-target mt-8">
                <div class="card-header">
                    <span class="h4"><open-icon glyph="envelope" /> {{ $root.translate("Emails") }}</span>
                </div>
                <div class="card-body">
                    <div class="columns mb-4">
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.zerospam.spam.ytd"
                                />
                                <div><span class="mx-1 dot" :style="{ 'background-color': color_code_by_index(0) }"></span>{{ $root.translate("Spam Blocked") }}</div>
                            </div>
                        </div>
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.zerospam.threat.ytd"
                                />
                                <div><span class="mx-1 dot" :style="{ 'background-color': color_code_by_index(1) }"></span>{{ $root.translate("Virus Blocked") }}</div>
                            </div>
                        </div>
                    </div>
                    <apexchart
                        type="bar"
                        :height="160"
                        :options="chart_options('zerospam')"
                        :series="chart_data('zerospam')"
                    />
                </div>
            </div>

            <div v-if="report.content.dnsfilter" id="threats" class="card nibnut-scroll-target mt-8">
                <div class="card-header">
                    <span class="h4"><open-icon glyph="shield-alt" /> {{ $root.translate("Threats") }}</span>
                </div>
                <div class="card-body">
                    <div class="columns mb-4">
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.dnsfilter.threats.stats.ytd"
                                />
                                <div><span class="mx-1 dot" :style="{ 'background-color': color_code_by_index(0) }"></span>{{ $root.translate("Cyber Threats Blocked") }}</div>
                            </div>
                        </div>
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.dnsfilter.sites.stats.ytd"
                                />
                                <div><span class="mx-1 dot" :style="{ 'background-color': color_code_by_index(1) }"></span>{{ $root.translate("Sites Blocked") }}</div>
                            </div>
                        </div>
                    </div>
                    <apexchart
                        type="bar"
                        :height="160"
                        :options="chart_options('dnsfilter')"
                        :series="chart_data('dnsfilter')"
                    />
                </div>
            </div>

            <div v-if="report.content.uptimerobot" id="network" class="card nibnut-scroll-target mt-8">
                <div class="card-header">
                    <span class="h4">
                        {{ $root.translate("Internet Connection") }}
                        <span class="text-small text-gray">{{ report.from_date | nibnut.date }} to {{ report.to_date | nibnut.date }}</span>
                    </span>
                </div>
                <div class="card-body">
                    <div class="columns mb-4">
                        <div class="column col-4 col-sm-12">
                            <div class="calendar-grid">
                                    <div class="calendar-grid-week">
                                        <div
                                            v-for="day in report.content.uptimerobot.rows"
                                            :key="day.id"
                                            :class="{ 'calendar-grid-day-light': (day.uptime < 98), 'calendar-grid-day-medium': (day.uptime <= 99.5), 'calendar-grid-day-dark': (day.uptime > 99.5) }"
                                            class="tooltip calendar-grid-day"
                                            :data-tooltip='uptime_tooltip(day)'
                                        >
                                            &nbsp;
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.uptimerobot.stats.interruptions"
                                />
                                <div>{{ $root.translate("Interruptions") }}</div>
                            </div>
                        </div>
                        <div class="column col-sm-12">
                            <div class="quick-stat">
                                <count-up-number
                                    :from="0"
                                    :to="report.content.uptimerobot.stats.downtime"
                                />
                                <div>{{ $root.translate("minutes of Downtime") }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import { is_page, profile_utilities } from "@/nibnut/mixins"

import { BaseLink, OpenIcon } from "@/nibnut/components"
import { CountUpNumber } from "@/custom/components"
import VueApexCharts from "vue-apexcharts"
window.Apex.colors = [
    "#2CB0DC", "#C642DB", "#DB7A16", "#84DB21", "#375CDB",
    "#DB4269", "#8F8715", "#71925F", "#D486B2", "#765F9B",
    "#F4C550", "#9C3821", "#718C9C", "#9A5B42", "#8A776D",
    "#692B56", "#CEC842", "#DBA716", "#4D494A", "#000000"
]

export default {
    mixins: [is_page, profile_utilities],
    components: {
        BaseLink,
        OpenIcon,
        CountUpNumber,
        Apexchart: VueApexCharts
    },
    mounted () {
        this.load()
    },
    watch: {
        $route: "load",
        profile_id: "load"
    },
    methods: {
        load () {
            if(this.profile_id) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "report",
                        query: { client_id: 0 }
                    }
                )
            }
        },
        color_code_by_index (index) {
            return window.Apex.colors[index]
        },
        chart_options (service_id) {
            const report = this.report
            const series = (service_id === "dnsfilter") ? [report.content[service_id].threats.graph_data.series[0], report.content[service_id].sites.graph_data.series[0]] : report.content[service_id].graph_data.series
            const nb_ticks = (Math.max(...series[0].data) % 4) || 1
            const options = {
                chart: {
                    id: `bar-${service_id}`,
                    height: 160,
                    type: "bar",
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    }
                },
                markers: {
                    size: 0
                },
                legend: {
                    show: false
                },
                grid: {
                    xaxis: {
                        lines: { show: false }
                    }
                },
                dataLabels: { enabled: false },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories: (service_id === "dnsfilter") ? report.content[service_id].threats.graph_data.categories : report.content[service_id].graph_data.categories
                },
                yaxis: {
                    tickAmount: nb_ticks,
                    labels: {
                        formatter: value => {
                            return value.toFixed(0)
                        }
                    }
                },
                series: series
            }
            return options
        },
        chart_data (service_id) {
            const report = this.report
            const data = (service_id === "dnsfilter") ? [report.content[service_id].threats.graph_data.series[0], report.content[service_id].sites.graph_data.series[0]] : report.content[service_id].graph_data.series
            return data
        },
        uptime_tooltip (day) {
            return `Date: ${day.date}
            ${day.interruptions} interruptions
            ${day.downtime} minutes downtime
            ${day.uptime}% uptime`
        }
    },
    computed: {
        report () {
            return this.entity_records("report").find(report => report.client_id === 0) || {}
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

#dashboard {
    h4 {
        margin-top: $unit-8;
        margin-bottom: $unit-6;
    }
    .quick-stat {
        font-size: 2.5rem;
        text-align: center;

        & > div {
            font-size: $font-size;
            color: $gray-color;
        }
    }
    .calendar-grid {
        margin: 0 auto;
        max-width: 300px;

        & > .calendar-grid-week {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .calendar-grid-day {
                flex: 0 0 auto;
                width: 1rem;
                height: 1rem;
                border-radius: 4px;
                margin: 2px;

                &.calendar-grid-day-dark {
                    background-color: $brand-color;
                }
                &.calendar-grid-day-medium {
                    background-color: rgba($brand-color, 0.8);
                }
                &.calendar-grid-day-light {
                    background-color: rgba($brand-color, 0.5);
                }

                @media (max-width: $size-sm) {
                    position: static;
                    top: auto;
                    left: auto;
                    transform: none;
                }
            }
        }
    }
    .toc {
        list-style: none;
        margin: $unit-1 0;
        padding: $unit-1 0;
        flex: 1 0 auto;

        .toc-item {
            display: inline-block;
            margin: 0;
            padding: $unit-1 0;

            &:not(:last-child) {
                margin-right: $unit-1;
            }

            &:not(:first-child) {
                &::before {
                    color: $gray-color-dark;
                    content: "|";
                    padding-right: $unit-2;
                }
            }
        }
        @media (max-width: $size-sm) {
            display: block;
            overflow-x: auto;
            padding-bottom: .75rem;
            flex: 1 1 auto;
        }
    }
}
</style>
